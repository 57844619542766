import { API_ROOT, API_ROOT_VM } from './api-config';

import store from './redux/store';
import { actionSetLogin } from './redux/actions/actionLogin';
import {
  actionSetMembers,
  actionSetMember,
  actionSetUsers,
  actionSetUser,
} from './redux/actions/actionUser';
import {
  actionSetSession,
  actionSetSessions,
} from './redux/actions/actionSession';
import { actionSetFeedbacks } from './redux/actions/actionFeedback';
import { actionSetCompanies } from './redux/actions/actionCompany';
import {
  actionSetModule,
  actionSetModuleAssignments,
  actionSetModules,
} from './redux/actions/actionModule';
import { actionSetResults } from './redux/actions/actionResult';
import superagent from 'superagent';

const post = async (url, data) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({
          ok: false,
          status: response.status,
          error: text,
        }));
      }
    })
    .catch((e) => {
      return { ok: false, fetchError: true, error: e.message };
    });
};

const postForm = async (url, data) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
    body: data,
    mode: 'cors',
  }).then((response) => {
    if (!response) return null;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      return response
        .text()
        .then((text) => ({ ok: false, status: response.status, error: text }));
    }
  });
};

const get = async (url, queryData) => {
  const query = queryData
    ? '?' +
      Object.keys(queryData)
        .map(
          (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryData[k])
        )
        .join('&')
    : '';
  return await fetch(url + query, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({ ok: false, error: text }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const getBinary = async (url, queryData) => {
  const query = queryData
    ? '?' +
      Object.keys(queryData)
        .map(
          (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryData[k])
        )
        .join('&')
    : '';
  return await fetch(url + query, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const downloadAsTextFile = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const graphQL = async (query) => {
  let result = post(API_ROOT + '/graphql', { query });
  return result;
};

export const toGraphQL = (object, addBrackets = true) => {
  if (!object) {
    return object;
  }

  const toString = (value) => {
    switch (typeof value) {
      case 'string':
        return JSON.stringify(value);
      case 'number':
        return value + '';
      case 'object':
        return toGraphQL(value);
      default:
        return value;
    }
  };

  if (object.length !== undefined) {
    const values = object.map((value) => toString(value)).join(', ');
    return addBrackets ? `[${values}]` : values;
  } else {
    const values = Object.keys(object)
      .filter((key) => object[key] !== undefined)
      .map((key) => key + ':' + toString(object[key]))
      .join(', ');

    return addBrackets ? `{${values}}` : values;
  }
};

// AUTHENTICATION

export const login = async (company, name, passwd) => {
  const result = await post(API_ROOT + '/auth/login', {
    company,
    name: name.trim(),
    passwd: passwd.trim(),
  });
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
  }
  return result;
};

export const passcode = async (uuid, passwd) => {
  const result = await post(API_ROOT + '/auth/passcode', {
    uuid: uuid.trim(),
    passwd: passwd.trim(),
  });
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
  }
  return result;
};

export const logout = async () => {
  const result = await get(API_ROOT + '/auth/logout');
  if (result && result.ok) {
    store.dispatch(actionSetLogin());
  }
  return result;
};

export const authStatus = async () => {
  const result = await get(API_ROOT + '/auth/status');
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
  }
  return result;
};

export const getipdata = async (ip) => {
  const result = await post(API_ROOT + '/auth/getipdata', { ip: ip });
  return result;
};

export const forgotEmail = async (email) => {
  const result = await post(API_ROOT + '/auth/forgotEmail', {
    email: email,
  });
  return result;
};

export const sendNewPasswd = async (passwd, key) => {
  const result = await post(API_ROOT + '/auth/sendNewPasswd', {
    passwd,
    key,
  });
  return result;
};

// USER

const user = async (uuid) => {
  const result = await graphQL(
    `{users(filter: {uuid: {EQ: "${uuid}"}}) { name, uuid, email, companies_uuid, role } }`
  );
  if (result && result.data && result.data.users) {
    store.dispatch(
      actionSetUser(result.data && result.data.users && result.data.users[0])
    );
  }
  return result;
};

export const users = async () => {
  const result = await graphQL(
    '{users { name, uuid, email, companies_uuid, role } }'
  );
  if (result && result.data && result.data.users) {
    store.dispatch(actionSetUsers(result.data && result.data.users));
  }
  return result;
};

export const createUser = async (user) => {
  return graphQL(`mutation { users_create(${toGraphQL(user, false)}) { ok } }`);
};

export const updateUser = async (user) => {
  return graphQL(
    `mutation { users_update(filter: {uuid: {EQ: "${
      user.uuid
    }"}}, update: {set: ${toGraphQL(user)} }) { ok } }`
  );
};

export const deleteUser = async (user) => {
  return graphQL(
    `mutation { users_delete(filter: {uuid: {EQ: "${user.uuid}"}}) { ok } }`
  );
};

// MODULESASSIGNMENT

const getModuleAssignmentFilter = (assignment) => {
  const attributes = [];
  if (assignment) {
    if (assignment.member_uuid) {
      attributes.push(`member_uuid: {EQ: "${assignment.member_uuid}"}`);
    }
    if (assignment.module_uuid) {
      attributes.push(`module_uuid: {EQ: "${assignment.module_uuid}"}`);
    }
    if (assignment.member_tag) {
      attributes.push(`member_tag: {EQ: "${assignment.member_tag}"}`);
    }
  }
  return `{${attributes.join(', ')}}`;
};

export const moduleAssignments = async () => {
  const result = await graphQL(
    `{moduleAssignments { module_uuid, member_uuid, member_tag } }`
  );
  if (result && result.data && result.data.moduleAssignments) {
    store.dispatch(
      actionSetModuleAssignments(result.data && result.data.moduleAssignments)
    );
    return result.data.moduleAssignments;
  }
  return result;
};

export const moduleAssignmentsByMember = async (member_uuid) => {
  const result = await graphQL(
    `{moduleAssignments(filter: {member_uuid: {EQ: "${member_uuid}"}}) { module_uuid, member_uuid, member_tag, created, from, until } }`
  );
  if (result && result.data && result.data.moduleAssignments) {
    store.dispatch(
      actionSetModuleAssignments(result.data && result.data.moduleAssignments)
    );
    return result.data.moduleAssignments;
  }
  return result;
};

export const moduleAssignmentsByCompany = async (company_uuid) => {
  const result = await graphQL(
    `{moduleAssignments(filter: {company_uuid: {EQ: "${company_uuid}"}}) { module_uuid, member_uuid, member_tag, created, from, until } }`
  );
  if (result && result.data && result.data.moduleAssignments) {
    store.dispatch(
      actionSetModuleAssignments(result.data && result.data.moduleAssignments)
    );
    return result.data.moduleAssignments;
  }
  return result;
};

const createModuleAssignment = (assignment) => {
  if (
    assignment &&
    assignment.module_uuid &&
    assignment.company_uuid &&
    (assignment.member_uuid || assignment.member_tag)
  ) {
    return graphQL(
      `mutation { moduleAssignments_create(${toGraphQL(
        assignment,
        false
      )}) { ok } }`
    );
  }
};

const updateModuleAssignment = (assignment) => {
  if (
    assignment &&
    assignment.module_uuid &&
    assignment.company_uuid &&
    (assignment.member_uuid || assignment.member_tag)
  ) {
    return graphQL(
      `mutation { moduleAssignments_update(filter: ${getModuleAssignmentFilter(
        assignment
      )}, update: {set: ${toGraphQL(assignment)} }) { ok } }`
    );
  }
};

const deleteModuleAssignment = (assignment) => {
  if (
    assignment &&
    assignment.module_uuid &&
    assignment.company_uuid &&
    (assignment.member_uuid || assignment.member_tag)
  ) {
    return graphQL(
      `mutation { moduleAssignments_delete(filter: ${getModuleAssignmentFilter(
        assignment
      )}) { ok } }`
    );
  }
};

// RESULTS

export const results = async () => {
  const result = await graphQL(
    `{results { module_uuid, member_uuid, completed, created, finished, modified, score, scoreMin, scoreMax } }`
  );
  if (result && result.data && result.data.results) {
    store.dispatch(actionSetResults(result.data && result.data.results));
    return result.data.results;
  }
  return result;
};

export const resultsByMemberAndModule = async (member_uuid, module_uuid) => {
  const result = await graphQL(
    `{results(filter: { member_uuid: {EQ: "${member_uuid}"}, module_uuid: {EQ: "${module_uuid}"}}) { completed, code, created, finished, modified, score, scoreMin, scoreMax } }`
  );
  if (result && result.data && result.data.results && result.data.results[0]) {
    return result.data.results[0];
  }
  return null;
};

export const resultsByMember = async (member_uuid) => {
  const result = await graphQL(
    `{results(filter: {member_uuid: {EQ: "${member_uuid}"}}) { module_uuid, member_uuid, completed, created, finished, modified, score, scoreMin, scoreMax  } }`
  );
  if (result && result.data && result.data.results) {
    store.dispatch(actionSetResults(result.data && result.data.results));
    return result.data.results;
  }
  return result;
};

export const resultsByModule = async (module_uuid) => {
  const result = await graphQL(
    `{results(filter: {module_uuid: {EQ: "${module_uuid}"}}) { module_uuid, member_uuid, completed, created, finished, modified, score, scoreMin, scoreMax  } }`
  );
  if (result && result.data && result.data.results) {
    store.dispatch(
      actionSetModuleAssignments(result.data && result.data.results)
    );
    return result.data.results;
  }
  return result;
};

const createResult = async (result) => {
  return await graphQL(
    `mutation { results_create(${toGraphQL(result, false)}) { ok, uuid } }`
  );
};

export const updateResultWithCode = async ({
  member_uuid,
  subcompany_uuid,
  company_uuid,
  module_uuid,
  finished,
  end,
  score,
  scoreMin,
  scoreMax,
}) => {
  const result = await post(API_ROOT + '/result/updateResultWithCode', {
    member_uuid,
    subcompany_uuid,
    company_uuid,
    module_uuid,
    finished,
    scoreMin,
    scoreMax,
    score,
    end,
  });
  return result;
};

// MEMBERS

export const members = async () => {
  const result = await graphQL(
    '{members { name, uuid, email, link, company_uuid, passive, company_uuid, scope } }'
  );
  if (result && result.data && result.data.members) {
    store.dispatch(actionSetMembers(result.data && result.data.members));
  }
  return result;
};

export const membersByCompany = async (uuid) => {
  const result = await graphQL(
    `{members(filter: {company_uuid: {EQ: "${uuid}"}}) { name, uuid, email, company_uuid, passive, scope } }`
  );
  if (result && result.data && result.data.members) {
    store.dispatch(actionSetMembers(result.data && result.data.members));
    return result.data.members;
  } else return null;
};

export const member = async (uuid) => {
  const result = await graphQL(
    `{members(filter: {uuid: {EQ: "${uuid}"}}) { uuid, link, company_uuid, name, passcode, language, passive, scope } }`
  );
  if (result && result.data && result.data.members && result.data.members[0]) {
    store.dispatch(actionSetMember(result.data.members[0]));
    return result.data.members[0];
  }
  return null;
};

export const updateMember = async (member) => {
  return graphQL(
    `mutation { members_update(filter: {uuid: {EQ: "${
      member.uuid
    }"}}, update: {set: ${toGraphQL(member)} }) { ok } }`
  );
};

export const createMember = async (member) => {
  return graphQL(
    `mutation { members_create(${toGraphQL(member, false)}) { ok, uuid } }`
  );
};

export const deleteMember = async (member) => {
  return graphQL(
    `mutation { members_delete(filter: {uuid: {EQ: "${member.uuid}"}}) { ok } }`
  );
};

// MODULES

export const modulesByCompany = async (comp_uuid) => {
  const result = await graphQL(
    `{ modules(filter: {company_uuid: {EQ: "${comp_uuid}"}}) { name, uuid,company_uuid, year, languages, labels, storybuilder_key, storybuilder_name, tag, showPause, showProgress, confirmUserID, status } }`
  );
  if (result && result.data && result.data.modules) {
    store.dispatch(actionSetModules(result.data && result.data.modules));
  }
  return result;
};

export const modulesByMember = async (member_uuid) => {
  const result = await get(API_ROOT + '/eval/getModulesByMember', {
    member_uuid,
  });
  return result;
};

export const modules = async () => {
  const result = await graphQL(
    '{ modules { name, uuid,company_uuid, languages, tag,  labels, storybuilder_key, year, storybuilder_name showPause, showProgress, confirmUserID, status } }'
  );
  if (result && result.data && result.data.modules) {
    store.dispatch(actionSetModules(result.data && result.data.modules));
    return result.data.modules;
  }
  return null;
};

export const moduleByKey = async (key) => {
  const result = await graphQL(
    `{ modules(filter: { storybuilder_key: {EQ: "${key}"}}) { name, uuid,company_uuid, year, languages, labels, storybuilder_key, storybuilder_name, showPause, showProgress, confirmUserID, status } }`
  );
  if (result && result.data && result.data.modules) {
    store.dispatch(actionSetModule(result.data && result.data.modules[0]));
    return result.data.modules[0];
  }
  return null;
};

export const moduleSingle = async (uuid) => {
  const result = await graphQL(
    `{ modules(filter: { uuid: {EQ: "${uuid}"}}) { name, uuid,company_uuid, year, languages, labels, storybuilder_key, storybuilder_name, tag, showPause, showProgress, confirmUserID, status } }`
  );
  if (result && result.data && result.data.modules) {
    store.dispatch(actionSetModule(result.data && result.data.modules[0]));
    return result.data.modules[0];
  }
  return null;
};

export const createModule = async (module) => {
  const result = await graphQL(
    `mutation { modules_create(${toGraphQL(module, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.modules_create;
};

export const updateModule = async (module) => {
  return graphQL(
    `mutation { modules_update(filter: {uuid: {EQ: "${
      module.uuid
    }"}}, update: {set: ${toGraphQL(module)} }) { ok } }`
  );
};

export const deleteModule = async (module) => {
  return graphQL(
    `mutation { modules_delete(filter: {uuid: {EQ: "${module.uuid}"}}) { ok } }`
  );
};

// COMPANIES

export const companies = async () => {
  const result = await graphQL(
    '{ companies { name, uuid, created, modified, active } }'
  );
  if (result && result.data && result.data.companies) {
    store.dispatch(actionSetCompanies(result.data && result.data.companies));
  }
  return result;
};

export const company = async (uuid) => {
  const result = await graphQL(
    `{companies(filter: {uuid: {EQ: "${uuid}"}}) { uuid, name, created, modified, active } }`
  );
  if (
    result &&
    result.data &&
    result.data.companies &&
    result.data.companies[0]
  ) {
    store.dispatch(actionSetModule(result.data.companies[0]));
  }
  return result;
};

export const createCompany = async (companies) => {
  const result = await graphQL(
    `mutation { companies_create(${toGraphQL(companies, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.companies_create;
};

export const updateCompany = async (companies) => {
  return graphQL(
    `mutation { companies_update(filter: {uuid: {EQ: "${
      companies.uuid
    }"}}, update: {set: ${toGraphQL(companies)} }) { ok } }`
  );
};

export const deleteCompany = async (companies) => {
  return graphQL(
    `mutation { companies_delete(filter: {uuid: {EQ: "${companies.uuid}"}}) { ok } }`
  );
};

export const changeCompany = async (company_uuid) => {
  const result = await post(API_ROOT + '/auth/change-company', {
    company_uuid: company_uuid,
  });
  return result;
};

// SESSIONS

export const sessions = async () => {
  const result = await graphQL(
    '{sessions { uuid, clientdata, member_uuid, module_uuid, pages_uuid, scoreMin, scoreMax, rating, scoreCurrent, status, created, modified } }'
  );
  if (result && result.data && result.data.sessions) {
    for (let i in result.data.sessions) {
      let session = result.data.sessions[i];
      if (
        typeof session.pages_uuid === 'string' ||
        session.pages_uuid instanceof String
      ) {
        if (session.pages_uuid === '') {
          session.pages_uuid = [];
        } else {
          // convert to array
          session.pages_uuid = JSON.parse(
            session.pages_uuid.replace(/'/g, '"')
          );
          deleteSession(session);
          createSession(session).then((result) => {
            if (result) {
            }
          });
        }
      }
    }
    store.dispatch(actionSetSessions(result.data.sessions));
  }
  return result;
};

export const session = async (uuid) => {
  const result = await graphQL(
    `{sessions(filter: {uuid: {EQ: "${uuid}"}}) { uuid, clientdata, member_uuid, sequences, rating, scoreMin, scoreMax, scoreCurrent, info, status, created, modified, variables } }`
  );
  if (
    result &&
    result.data &&
    result.data.sessions &&
    result.data.sessions[0]
  ) {
    store.dispatch(actionSetSession(result.data.sessions[0]));
  }
  return result;
};

export const sessionsByCompanyModule = async (company_uuid, module_uuid) => {
  const result = await get(API_ROOT + '/eval/getSessionsByCompanyModule', {
    company_uuid,
    module_uuid,
  });
  if (result && result.ok && result.data) {
    store.dispatch(actionSetSessions(result.data));
  }
  return result;
};

export const sessionsMember = async (uuid, module_uuid) => {
  const result = await graphQL(
    `{sessions(sort: {created: ASC }, filter: {member_uuid: {EQ: "${uuid}"}, module_uuid: {EQ: "${module_uuid}"}}) { uuid, clientdata, member_uuid, rating, scoreMin, scoreMax, scoreCurrent, status, info, created, modified, variables } }`
  );
  if (
    result &&
    result.data &&
    result.data.sessions &&
    result.data.sessions[0]
  ) {
    store.dispatch(actionSetSessions(result.data.sessions[0]));
  }
  return result;
};

export const createSession = async (session) => {
  const result = await graphQL(
    `mutation { sessions_create(${toGraphQL(session, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.sessions_create;
};

export const updateSession = async (session) => {
  return graphQL(
    `mutation { sessions_update(filter: {uuid: {EQ: "${
      session.uuid
    }"}}, update: {set: ${toGraphQL(session)} }) { ok } }`
  );
};

export const updateSessionReplaceUUID = async (uuid, session) => {
  return graphQL(
    `mutation { sessions_update(filter: {uuid: {EQ: "${uuid}"}}, update: {set: ${toGraphQL(
      session
    )} }) { ok } }`
  );
};

export const deleteSession = async (session) => {
  return graphQL(
    `mutation { sessions_delete(filter: {uuid: {EQ: "${session.uuid}"}}) { ok } }`
  );
};

// FEEDBACK

export const feedbacks = async () => {
  const result = await graphQL(
    `{feedbacks { uuid, member_uuid, company_uuid, user_uuid, module_uuid, session_uuid, sequence_uuid, sequence_name, created, language, status, comments, text } }`
  );
  if (result && result.data && result.data.feedbacks) {
    store.dispatch(actionSetFeedbacks(result.data.feedbacks));
  }
  return result;
};

export const createFeedback = async (data) => {
  const result = await graphQL(
    `mutation { feedbacks_create(${toGraphQL(data, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.feedbacks_create;
};

export const updateFeedback = async (feedback) => {
  return graphQL(
    `mutation { feedbacks_update(filter: {uuid: {EQ: "${
      feedback.uuid
    }"}}, update: {set: ${toGraphQL(feedback)} }) { ok } }`
  );
};

export const uploadHtmlImage = async (image, media_uuid) => {
  const base64 = image.src.replace(/^data:image\/(png|jpg);base64,/, '');
  const result = await post(API_ROOT + '/media/uploadBase64', {
    image: base64,
    media_uuid,
  });
  return result;
};

// VM

export const vmProjectList = async () => {
  const result = await get(API_ROOT + '/vm/projectlist');
  return result;
};

export const vmProjectInfo = async (key) => {
  const result = await get(API_ROOT + '/vm/projectinfo', { key });
  return result;
};

export const vmProjectBookmarks = async (key) => {
  const result = await get(API_ROOT + '/vm/projectbookmarks', { key });
  return result;
};

export const pingVuppetmasterEngine = async (host, cb) => {
  try {
    superagent
      .get(host + '/vm/cdk')
      .on('error', (err) => {
        cb(null);
      })
      .then((response) => {
        const body = response.body;
        cb(body);
      });
  } catch (e) {
    cb(null);
  }
};

// EVAL

export const evalSessions = async (company_uuid, module_uuid) => {
  const result = await get(API_ROOT + '/eval/sessions', {
    company_uuid,
    module_uuid,
  });
  return result;
};

export default {
  post,
  get,
  graphQL,

  login,
  passcode,
  logout,
  authStatus,
  getipdata,
  pingVuppetmasterEngine,
  forgotEmail,
  sendNewPasswd,

  user,
  users,
  createUser,
  updateUser,
  deleteUser,

  members,
  membersByCompany,
  member,
  updateMember,
  deleteMember,
  createMember,

  moduleAssignments,
  moduleAssignmentsByMember,
  moduleAssignmentsByCompany,
  createModuleAssignment,
  updateModuleAssignment,
  deleteModuleAssignment,

  results,
  resultsByMemberAndModule,
  resultsByMember,
  resultsByModule,
  createResult,
  updateResultWithCode,

  modules,
  moduleSingle,
  modulesByCompany,
  modulesByMember,
  moduleByKey,
  createModule,
  updateModule,
  deleteModule,

  sessions,
  sessionsMember,
  session,
  sessionsByCompanyModule,
  createSession,
  updateSession,
  updateSessionReplaceUUID,
  deleteSession,

  feedbacks,
  createFeedback,
  updateFeedback,
  uploadHtmlImage,

  companies,
  company,
  createCompany,
  updateCompany,
  deleteCompany,
  changeCompany,

  vmProjectList,
  vmProjectInfo,
  vmProjectBookmarks,

  evalSessions,
};
